:root {
  --dark: #181818;
  --light: #aaaaaa;
  --accent: #222222;
  --white: #FFFFFF;
}


body {
  margin: 0;
  font-family: system-ui, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: var(--dark);
  font-weight: normal;
  overflow-x: hidden;
}

nav {
  position: relative;
  padding: 0 5rem;
  border-bottom: thin solid var(--accent);
  display: flex;
  justify-content: space-between;
  height: 3rem;
  align-items: center;
  margin-bottom: 1rem;
}

nav h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: normal;
  color: var(--white);
  margin: 0;
}

.products {
  display: flex;
  padding: 1rem 5rem;
}


.product {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 15rem;
  margin-right: 1rem;
  border-radius: 7px;
  padding: 10px 0;
  overflow: hidden;
  border: thin solid var(--accent);
}

.product-image {
  height: 8rem;
  width: 14rem;
}
.product p {
  margin: 0;
  margin-top: 5px;
  color: var(--white);
  text-align: left;
}

.product-image img {
  height: 100%;
  object-fit: cover;
  border-radius: 7px;
  width: 100%;
}

.purchase {
  display: flex;
  justify-content: space-between;
  height: 2rem;
  width: 14rem;
}

.variant {
  margin: 5px;
  border: thin solid var(--accent);
  color: var(--light);
  background: none;
  width: 100%;
  border-radius: 5px;
  min-height: 1.5rem;
  padding-bottom: 3px;
  margin-top: 10px;
  cursor: pointer;
}

.variant:hover {
  color: var(--white);
  border: thin solid var(--light);
}


.cart {
  display: flex;
  align-items: center;

  justify-content: center;
  position: relative;
}

.cart-num {
  position: absolute;
  top: -10px;
  color: var(--white);
  right: -5px;
}

.cart svg {
  color: var(--light);
}

.cart svg:hover {
  color: var(--white);
}


.number-input input{
  min-height: 1.2rem;
  border: thin solid black;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cart-closed {
  position: absolute;
  top: 3.5rem;
  transform: translateX(30rem);
  transition: all ease-in-out 150ms;
  right: 10px;
  border-radius: 5px;
  padding: 10px;
  width: 20rem;
  border: thin solid var(--accent);
}


.cart-expanded {
  position: absolute;
  top: 3.5rem;
  right: 10px;
  border-radius: 5px;
  transition: all ease-in-out 150ms;
  padding: 10px;
  transform: translateX(0rem);
  width: 20rem;
  border: thin solid var(--accent);
}

.cart-title {
  color: var(--light);

  font-size: 17px;
}

.cart-items {
  overflow-y: auto;
  width: 100%;
  max-height: 40rem;
  margin-top: 15px;
}

.cart-item {
  display: flex;
  align-items: center;
  height: 5rem;
  padding: 0 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: thin solid var(--accent);
}

.cart-item img {
  height: 4rem;
  object-fit: cover;
  max-width: 4rem;
  border-radius: 5px;
  width: auto;
}

.cart-item h1 {
  margin: 0;
  color: var(--white);
  font-size: 15px;
  margin-left: 10px;
  text-align: center;
}

.cart-item p {
  margin-left: auto;
  color: var(--white);
}

.checkout {
  margin-top: 10px; 
  border: thin solid var(--accent);
  background: none;
  padding: 5px 10px;
  color: var(--white);
  border-radius: 5px;
}

.checkout:hover {
  border: thin solid var(--light);
}

.loading {
  margin-top: 10px; 
  filter: blur(.3px);
  opacity: 0.8;
  user-select: none;
  pointer-events: none;
  border: thin solid var(--accent);
  background-color: var(--accent);
  padding: 5px 10px;
  color: var(--white);
  border-radius: 5px;
}

.empty {
  color: var(--light);
}